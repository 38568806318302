import React, { useContext, useEffect, useMemo, useRef } from "react";

import LayoutContext from "../contexts/layout";
import PortableText from "./portable-text";
import { cn } from "../lib/helpers";

import * as styles from "./carousel-layout.module.css";
import * as navStyles from "./nav.module.css";

const SECTION_TYPES = {
  IMAGE: "IMAGE",
  YOUTUBE: "YOUTUBE",
  COLLECTION: "COLLECTION",
  CONTRIBUTOR: "CONTRIBUTOR"
};

const sectionWrapperClasses = {
  [SECTION_TYPES.IMAGE]: styles.imageBlock,
  [SECTION_TYPES.YOUTUBE]: styles.youtubeBlock,
  [SECTION_TYPES.COLLECTION]: styles.collectionBlock,
  [SECTION_TYPES.CONTRIBUTOR]: styles.contributorBlock
};

function CarouselLayout({ title, blocks, contributors }) {
  const carouselEl = useRef(null);
  const { isNavOpen, isLightboxOpen } = useContext(LayoutContext);

  // Translate vertical scroll to horizontal scroll.
  useEffect(() => {
    if (isLightboxOpen) return;

    function transformScroll(e) {
      if (!e.deltaY) return;
      // Scrolling in the nav shouldn’t trigger a horizontal scroll.
      if (e.target.closest(`.${navStyles.showNav}`)) return;
      // Scrolling in a content block shouldn’t trigger a horizontal scroll.
      const sectionScrollingWithin =
        e.target.closest(
          `.${sectionWrapperClasses[SECTION_TYPES.COLLECTION]}`.replace(
            /\+/g,
            "\\+"
          )
        ) ??
        e.target.closest(
          `.${sectionWrapperClasses[SECTION_TYPES.CONTRIBUTOR]}`.replace(
            /\+/g,
            "\\+"
          )
        );
      if (
        sectionScrollingWithin &&
        sectionScrollingWithin.scrollHeight !==
          sectionScrollingWithin.clientHeight
      ) {
        return;
      }
      carouselEl.current.scrollLeft += e.deltaY * 2;
    }

    const scrollEl = document.scrollingElement || document.documentElement;
    scrollEl.addEventListener("wheel", transformScroll);

    return () => scrollEl.removeEventListener("wheel", transformScroll);
  }, [isLightboxOpen, isNavOpen]);

  const sections = useMemo(
    () =>
      blocks?.reduce((acc, block) => {
        const currentSection = acc[acc.length - 1];
        switch (block._type) {
          case "break":
            currentSection.closed = true;
            break;
          case "mainImage":
          case "youtube":
            acc.push({
              type: SECTION_TYPES[
                block._type === "mainImage" ? "IMAGE" : "YOUTUBE"
              ],
              blocks: [block],
              closed: true
            });
            break;
          default:
            if (currentSection && !currentSection.closed) {
              currentSection.blocks.push(block);
            } else {
              acc.push({
                type: SECTION_TYPES.COLLECTION,
                blocks: [block]
              });
            }
        }
        return acc;
      }, []),
    [blocks]
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div
        ref={carouselEl}
        className={styles.carousel}
        style={{ overflowX: isLightboxOpen ? "hidden" : "scroll" }}
      >
        {sections?.map((section, i, arr) => {
          const extraProps = {};
          if (i === arr.length - 1) {
            extraProps.id = "refs";
          }
          return (
            <div
              className={cn(sectionWrapperClasses[section.type])}
              key={i}
              {...extraProps}
            >
              <PortableText blocks={section.blocks} />
            </div>
          );
        })}
        {contributors && (
          <div className={sectionWrapperClasses[SECTION_TYPES.CONTRIBUTOR]}>
            {contributors.map((contributor) => (
              <div key={contributor.author.id} className={styles.contributor}>
                <h2>{contributor.author.name}</h2>
                <PortableText blocks={contributor.author._rawBio} />
              </div>
            ))}
          </div>
        )}
        <div id="contributors" aria-hidden={true} />
      </div>
    </div>
  );
}

export default CarouselLayout;
