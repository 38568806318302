import React from "react";
import { graphql, Link } from "gatsby";

import SEO from "../../components/seo";
import CarouselLayout from "../../components/carousel-layout";

import * as styles from "../../components/cycle-page.module.css";

export const query = graphql`
  query CounterfactualContextPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawCounterfactualContextPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const CounterfactualContextPage = ({ data }) => {
  return (
    <>
      <SEO title="Counterfactual" />
      <CarouselLayout
        title={
          <h1>
            <Link to=".." className={styles.titleLink}>
              Counterfactual
            </Link>
          </h1>
        }
        blocks={data?.site?._rawCounterfactualContextPageContent}
      />
    </>
  );
};

export default CounterfactualContextPage;
